// Breakpoints >= browser width
$large-desktop-width: 1440px !default;
$desktop-width: 1024px !default;
$tablet-width: 768px !default;
$phablet-width: 480px !default;

@mixin mobile-only {
	@media (max-width: #{$tablet-width - 1}) {
		@content;
	}
}

@mixin phablet {
	@media (min-width: #{$phablet-width}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

@mixin largeDesktop {
	@media (min-width: #{$large-desktop-width}) {
		@content;
	}
}
