.carousel-content {
    &.main {
        
        @include tablet {
            width: 25vw;
            height: 30vw;
            margin-left: auto;
            margin-right: auto;
            transform: translateY(-400px);
        }

        .carousel-content-item {
            &-panel {
                height: 500px;
                line-height: 500px;
                color: #fff;
                text-align: center;
                background: #364d79;
                background-size: cover;
                background-position-x: center;
                background-position-y: center;
                @include tablet {
                    height: 30vw;
                    line-height: 30vw;
                }
            }
        }
    }
}