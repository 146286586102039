.header {
    background-color: white;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1000;
    &-menu {
        flex: 1;
        padding: 0;
        min-width: 0;
    }

    &-logo {
        filter: drop-shadow(2px 2px 0px #d6d6d6);
        cursor: pointer;
    }

    &-actions {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        margin-right: 15px;
    }
}