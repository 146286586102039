.scrolling-container {
    position: relative;
    width: 100vw;
    height: 140px; 
    overflow: hidden;
    background: #00000000; 
    &.left-to-right {
        transform: translateY(175px);
        @include tablet {
          transform: translateY(125px);
        }
    }
    &.right-to-left {
        transform: translateY(-125px);
        @include tablet {
          transform: translateY(-125px);
        }
    }
  }
  
  .scrolling-text {
    position: absolute;
    white-space: nowrap;
    top: 0;
    transition: 5s linear;
    // animation: scrollText linear infinite;
  }
  
  .scrolling-text-content {
    color: var(--primary);
    filter: opacity(0.3);
    font-size: 50px;
    line-height: 1em;
    margin: 0;
    @include tablet {
      font-size: 120px;
    }
  }
  
  @keyframes scrollText {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  // .left-to-right .scrolling-text {
  //   animation: scrollTextLeftToRight 110s ease-in-out infinite;
  // }
  
  // .right-to-left .scrolling-text {
  //   animation: scrollTextRightToLeft 110s ease-in-out infinite;
  // }
  
  // @keyframes scrollTextLeftToRight {
  //   0% {
  //       transform: translate(-12%);
  //   }
  //   50% {
  //       transform: translateX(180%);
  //   }
  //   100% {
  //       transform: translateX(-12%);
  //   }
  // }
  
  // @keyframes scrollTextRightToLeft {
  //   0% {
  //       transform: translateX(120%);
  //   }
  //   50% {
  //       transform: translateX(-12%);
  //   }
  //   100% {
  //       transform: translateX(120%);
  //   }
  // }