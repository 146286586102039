.how-it-works {
    &-item {
        &-pic {
            width: 145px;
            padding: 50px 15px;
            @include tablet {
                width: 25%;
                padding: 0;
            }
        }
    }
}