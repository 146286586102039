.packs {
    &-item {
        width: 100%;
        max-width: 45vw;
        @include tablet {
            max-width: 300px;
        }

        &-price {
            .ant-card-meta-title {
                font-size: 18px !important;
                color: var(--primary);
                font-weight: bold;
                @include tablet {
                    font-size: 25px !important;
                }
            }
        }

        .ant-card-body {
            padding: 10px;
          @include tablet {
            padding: 24px;
          }   
        }
    }
}