.menu-gallery {
    padding: 3vw;
    z-index: 2;
    @include tablet {
        padding: 1vw;
    }
    &-item {
        width: 100%;
        max-width: 45vw;
        @include tablet {
            max-width: 300px;
        }

        .ant-card-body {
            padding: 0;
        }
    }
}