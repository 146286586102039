@import '../../assets/scss/resolutions.scss';
@import '../../components/core/header/index.scss';
@import '../../components/carousel-content/index.scss';
@import '../../components/core/scroll-text-effect/index.scss';
@import '../../components/core/footer/index.scss';
@import '../../components/packs/index.scss';
@import '../../components/menu-gallery/index.scss';
@import '../../components/how-it-works/index.scss';

.home {
    main {
        min-height: 100vh;
        .us {
            &-desc {
                flex: 1;
                padding: 5vw 5vw;
                text-align: start;
                max-width: 100%;
                @include tablet {
                    text-align: start;
                    max-width: 50%;
                }
                span {
                    font-weight: bold;
                    font-size: 25px;
                    @include tablet {
                        font-size: 45px;
                    }
                }
                p {
                    font-size: 20px;
                    max-width: 100%;
                    @include tablet {
                        max-width: 30vw;
                    }
                }
                .shop-now {
                    span {
                        font-size: medium !important;
                    }
                }
            }
            &-pic {
                display: none;
                @include tablet {
                    display: flex;
                    flex: 1;
                    height: 100vh;
                    max-height: 500px;
                    background-image: url(.././../assets/img/base-img.jpg);
                    background-position: center;
                    background-size: cover;
                }
            }
        }

        .ant-carousel {
            @include tablet {
                height: 250px;
            }
        }
        .section1 {
            padding: 50px 5vw;
            @include tablet {
                padding: 50px 25vw;
            }
        }

        .section2,.section3,.section4,.section5,.section6 {
            margin-bottom: 50px;
            .title {
                font-size: 40px;
                font-weight: bold;
                z-index: 2;
            }
        }

        .section4 {
            background-image: url('../../assets/img/delivery-background.jpg');
            background-position-x: center;
            background-position-y: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 100px 2vw;
            color: white;
            text-shadow: 1px 1px 2px black;
            min-height: 350px;
            position: relative;
            p {
                font-size: 25px;
                z-index: 2;
            }

            .action-btn{
                z-index: 2;
                max-width: fit-content;
                align-self: center;
                font-size: 25px;
                padding: 20px 15px;
            }
            .blur-background {
                backdrop-filter: blur(6px);
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            .highlighted-text {
                color: var(--primary);
                filter: brightness(1.8);
            }
        }
    }
}