.footer {
    background: var(--backgroundDark);
    margin-top: 100px;
    &-legal {
        color: var(--textMuted);
        a {
            color: var(--textMuted);
        }
    }
    .icon {
        font-size: x-large;
        &.face {
            color: #0866ff;
        }
        &.insta {
            color: #be5281;
        }
        &.whats {
            color: green;
        }
    }
}