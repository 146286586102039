@import './resolutions.scss';
/* vietnamese */
@font-face {
  font-family: 'quicksand-light';
  font-style: normal;
  font-weight: 300;
  src:  url('../fonts/Quicksand-Light.ttf') format('truetype'),
      url('../fonts/Quicksand-Light.ttf') format('truetype');
}
/* latin-ext */
@font-face {
  font-family: 'quicksand-regular';
  font-style: normal;
  font-weight: 400;
  src:  url('../fonts/Quicksand-Regular.ttf') format('truetype'),
      url('../fonts/Quicksand-Regular.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'quicksand-medium';
  font-style: normal;
  font-weight: 500;
  src:  url('../fonts/Quicksand-Medium.ttf') format('truetype'),
      url('../fonts/Quicksand-Medium.ttf') format('truetype');
}
/* vietnamese */
@font-face {
  font-family: 'quicksand-semibold';
  font-style: normal;
  font-weight: 600;
  src:  url('../fonts/Quicksand-SemiBold.ttf') format('truetype'),
      url('../fonts/Quicksand-SemiBold.ttf') format('truetype');
}
/* latin-ext */
@font-face {
  font-family: 'quicksand-bold';
  font-style: normal;
  font-weight: 700;
  src:  url('../fonts/Quicksand-Bold.ttf') format('truetype'),
      url('../fonts/Quicksand-Bold.ttf') format('truetype');
}

body {
  font-family: 'quicksand-regular' !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-light {
  font-family: 'quicksand-light' !important;
}

.text-regular {
  font-family: 'quicksand-regular' !important;
}

.text-medium {
  font-family: 'quicksand-medium' !important;
}

.text-semibold {
  font-family: 'quicksand-semibold' !important;
}

.text-bold {
  font-family: 'quicksand-bold' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-primary {
  background: var(--primary);
  &:hover {
      background: var(--primary) !important;
      filter: brightness(1.3);
  }
}